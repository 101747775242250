<template>
  <div :class="chatListClass" class="chat-list">
    <Banner>
      <div class="banner-content">
        <MeepIconChat class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.chat") }}</span>
      </div>
    </Banner>
    <!-- <PageHeader
      :title="title"
      :has-back="true"
      :has-actions="(isMobile && showChat) || !isMobile"
      @back="goBack"
    >
      <md-button
        v-if="(isMobile && showChat) || !isMobile"
        type="button"
        class="chat-list__download md-primary"
        @click="downloadMessages"
      >
        {{ $t("chat.list-download-button") }}
      </md-button>
    </PageHeader> -->
    <div class="chat-list-content page-layout">
      <div
        :class="{
          'chat-list__content--mobile': isMobile,
          'chat-list__content--jep': isJEP,
        }"
        class="chat-list__content"
      >
        <div
          v-if="(!showChat && isMobile) || !isMobile"
          class="chat-list__recipients"
        >
          <div class="chat-list__search-bar">
            <input
              v-model="recipientQuery"
              class="chat-list__search-input"
              name="recipientQuery"
              placeholder=""
              type="text"
            />

            <MeepIconSearchi class="chat-list__search-icon" />
          </div>

          <md-list
            v-if="filter.length"
            class="chat-list__recipients-list md-double-line"
          >
            <Recipient
              v-for="(chat, index) in filter"
              :key="index"
              :chat-id="chat.id"
              :class="{
                'chat-list__recipient--active': chat.id === currentThreadId,
              }"
              :last-message="chat.last_message"
              :name="chat.name"
              :unread-count="chat.unread_count"
              class="chat-list__recipient"
              @showChatDetail="onShowChatDetail"
            />
          </md-list>

          <!-- À afficher lorqu'il n'y a aucune conversations -->
          <md-empty-state v-else md-description="Aucune conversations trouvées">
          </md-empty-state>
        </div>

        <div
          v-if="((showChat && isMobile) || !isMobile) && initDone"
          class="chat-list__messages"
        >
          <div class="chat-list__messages-border">
            <div class="chat-list__messages-border-list">
              <div class="chat-list__messages-border-content">
                <div v-if="isLoadMessageMore" class="chat-list__load-more">
                  <md-button
                    class="md-primary load-more"
                    @click.native="loadMoreMessage"
                  >
                    Charger plus...
                  </md-button>
                </div>

                <div v-for="(messageDate, index) in messages.key" :key="index">
                  <p class="chat-list__messages-date">{{ messageDate }}</p>
                  <ChatMessage
                    v-for="(message, index) in messages.data[messageDate]"
                    :id="`chat-message-${message.id}`"
                    :key="index"
                    :is-last-message="
                      index === messages.data[messageDate].length - 1 &&
                        messageDate === messages.key[messages.key.length - 1]
                    "
                    :message="message"
                    :users="users"
                    class="chat-list__message"
                  />
                </div>
              </div>
            </div>
          </div>

          <form class="chat-list__send" @submit.prevent="sendMessage">
            <textarea
              v-model="text"
              :readonly="isSendingMessage"
              class="chat-list__send-input"
              placeholder="Message..."
              type="text"
              @keydown.enter.exact.prevent
              @keydown.enter.shift.exact="newline"
              @keyup.enter.exact="sendMessage"
            />
            <button
              :disabled="isSendingMessage"
              class="chat-list__send-btn"
              type="submit"
            >
              <md-progress-spinner
                v-if="isSendingMessage"
                :md-diameter="20"
                :md-stroke="3"
                md-mode="indeterminate"
              />

              <MeepIconSend class="chat-list__send-icon" />
            </button>
          </form>
        </div>

        <md-button
          :class="{
            'md-primary': !isJEP,
            'md-alternate': isJEP,
          }"
          class="md-raised header-action header-action-icon"
          @click="downloadMessages"
        >
          <MeepIconDownload class="app-icon" />
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import chatModel from "../../model/chat";
import companiesModel from "../../model/companies";
/* Audio */
import newMessageSoundFile from "../../assets/audio/chat_new_message.mp3";
/* Pour la sauvegarde de fichiers */
import FileSaver from "file-saver";

import Recipient from "./Recipient";
import ChatMessage from "./ChatMessage";
import { mapActions, mapGetters, mapState } from "vuex";
import { createBlobFromBinary, getUserMapByScope } from "@/services/util";
import MeepIconSearchi from "../../components/icons/MeepIconSearchi";
import Banner from "@/components/Banner";
import MeepIconChat from "@/components/icons/MeepIconChat.vue";
import MeepIconSend from "@/components/icons/MeepIconSend.vue";
import MeepIconDownload from "@/components/icons/MeepIconDownload.vue";

export default {
  name: "ChatList",

  components: {
    MeepIconSearchi,
    Recipient,
    ChatMessage,
    Banner,
    MeepIconChat,
    MeepIconSend,
    MeepIconDownload,
  },

  data() {
    return {
      interval: null,
      initDone: false,
      showChat: false,
      isSendingMessage: false,
      error: false,
      users: {} /* Cache des utilisateurs */,
      company: {},
      text: "",
      recipientQuery: "",
      currentThreadId: null,
      scrollHeight: 0,
      currentFile: {} /* Dossier ou fichier courrant */,
      dataUrl: null,

      newMessageSound: new Audio(newMessageSoundFile),
    };
  },

  computed: {
    ...mapGetters([
      "self",
      "isMobile",
      "isJEPA",
      "isLCM",
      "isJEP",
      "isCE",
      "isGC",
    ]),

    ...mapState(["conversations", "messages", "isLoadMessageMore"]),

    showOnlyChat() {
      return this.isMobile && this.showChat;
    },

    filter() {
      if (this.query === "") {
        return this.conversations;
      } else {
        let q = this.recipientQuery.toLowerCase();
        return this.conversations.filter(item => {
          return item.name.toLowerCase().indexOf(q) > -1;
        });
      }
    },

    title() {
      return this.$t("communicate.chat");
    },

    chatListClass() {
      return {
        "chat-list-jepa": this.isJEPA,
        "chat-list-lcm": this.isLCM,
        "chat-list-ce": this.isCE,
        "chat-list-jep": this.isJEP,
        "chat-list-gc": this.isGC,
      };
    },
  },

  watch: {
    $route: "loadData",

    isMobile: "loadData",
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.$store.commit("TOGGLE_SHOW_CHAT", { company_id: "", messages: [] });
  },

  methods: {
    ...mapActions(["SHOW_CHAT", "LOAD_MORE_CHAT"]),

    goBack() {
      window.history.back();
    },

    onShowChatDetail(id) {
      if (id !== this.currentThreadId) {
        this.$router.push("/dashboard/communicate/chat/" + id);
      }
    },

    async showChatDetail(id) {
      try {
        this.currentThreadId = id;

        await this.SHOW_CHAT({
          company_id: this.currentThreadId,
          unreadOnly: 0,
        });

        await this.cacheUser();

        this.companyID = id;

        this.scrollToBottom();
      } catch (error) {
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    async cacheUser() {
      this.initDone = false;
      // Reinit
      this.users = {};
      const users = {};
      this.company = await companiesModel.get(this.currentThreadId);

      getUserMapByScope(this.company.users, users);
      getUserMapByScope(this.company.collaborators, users);
      getUserMapByScope(this.company.administrators, users);

      this.users = users;
      this.initDone = true;
    },

    async sendMessage(e) {
      if (!this.isSendingMessage) {
        this.isSendingMessage = true;
        if (this.text === "" || e.shiftKey) {
          this.isSendingMessage = false;
          return;
        }

        try {
          const message = await chatModel.sendMessage(
            this.companyID,
            this.text
          );
          this.$store.commit("ADD_MESSAGE", message);
          this.scrollToBottom();
          this.text = "";
        } catch (err) {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        } finally {
          this.isSendingMessage = false;
        }
      }
    },

    /* Télécharger le fichier */
    downloadMessages() {
      chatModel.downloadMessages(this.currentThreadId).then(downloadedFile => {
        let blob = createBlobFromBinary(
          downloadedFile.binary,
          downloadedFile.mimeType
        );
        FileSaver.saveAs(blob, `${this.company.name}.txt`);
      });
    },

    loadMoreMessage() {
      this.LOAD_MORE_CHAT();
    },

    scrollToBottom() {
      this.$nextTick(() => {
        if (this.messages) {
          const latestDate = this.messages.key[this.messages.key.length - 1];
          const indexOflastChat = this.messages.data[latestDate].length - 1;
          const latestChatId = this.messages.data[latestDate][indexOflastChat]
            .id;

          if (this.messages.data) {
            document
              .querySelector(`#chat-message-${latestChatId}`)
              .scrollIntoView();
          }
        }
      });
    },

    async loadData() {
      const conversationId = this.$route.params.id;

      if (this.isMobile) {
        if (conversationId) {
          this.showChat = true;
          this.showChatDetail(conversationId);
        } else {
          this.showChat = false;
          this.$store.commit("TOGGLE_SHOW_CHAT", {
            company_id: "",
            messages: [],
          });

          await this.$store.dispatch("SHOW_CONVERSATIONS");
        }
      } else {
        // Desktop
        await this.$store.dispatch("SHOW_CONVERSATIONS");

        if (this.conversations.length > 0 && !conversationId) {
          // Go to first conversation
          await this.$router.replace(
            "/dashboard/communicate/chat/" + this.conversations[0].id
          );
        } else if (conversationId) {
          this.showChatDetail(conversationId);
        }
      }
    },

    newLine() {
      this.text = `${this.text}\n`;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variable.scss";
#app {
  .chat-list {
    .md-title {
      margin-left: 30px;
      @include for-lg {
        margin-left: 0;
      }
    }
    &-content {
      width: 100%;
      padding: 0 54px;
      &.page-layout {
        overflow: hidden !important;
        height: 100% !important;
      }
      .recipient__name {
        font-family: var(--font-extrabold);
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: toRem(12);
        @include for-lg {
          font-size: toRem(18);
        }
      }
      .recipient__last-message {
        font-size: toRem(11);
        @include for-lg {
          font-size: toRem(16);
        }
      }
    }
    &__download {
      border: none;
      border-radius: 5px;
      background: var(--bg-primary);
      min-width: 0;
      margin: 0;
      padding: 0 8px;
      height: 40px;
    }
    &__download-icon {
      width: 20px;
      height: 20px;
      path {
        fill: white !important;
      }
    }
    &__load-more {
      width: 100%;
      display: flex;
      justify-items: center;
      align-content: center;
      .load-more {
        flex: 150px 1 1;
      }
    }
    &__search-icon {
      width: 15px;
      height: 15px;
      fill: grey;
      position: absolute;
      top: 15px;
      right: 32px;
      @include for-lg {
        width: 20px;
        height: 20px;
        top: 20px;
        right: 32px;
      }
    }
    &__send-icon {
      width: 21px;
      height: 18px;
      position: absolute;
      top: 34px;
      right: 30px;
      @include for-lg {
        top: 12px;
        width: 35px;
        height: 35px;
      }
    }
    &__search-bar {
      width: 100%;
      height: 40px;
      margin: 10px 0;
      padding-right: 20px;
      position: relative;
    }
    &__search-input {
      height: 26px;
      margin: 10px 0;
      width: 100%;
      background: #f2f5f7;
      border: none;
      padding: 0 35px 0px 20px;
      border-radius: 5px;
      @include for-lg {
        height: 42px;
      }
    }
    &__header {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__content {
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      height: 70vh;
      &--mobile {
        .chat-list__recipients {
          flex: 1 1 100%;
        }
        .chat-list__messages {
          flex: 1 1 100%;
        }
      }
      &--jep {
        .chat-list__search-bar {
          g {
            stroke: var(--bg-primary);
          }
        }
      }
    }
    &__recipients {
      overflow: hidden;
      padding-left: 20px;
      padding: 0 0 20px 20px;
      flex: 29% 0 0; // 2% space gap between elements
      border-radius: 27px;
      background-color: white;
    }
    &__recipients-list {
      height: calc(100% - 60px);
      overflow: auto;
      margin-top: 20px;
      padding: 0;
      .md-list-item-content {
        min-height: unset;
        height: 58px;
        @include for-lg {
          height: 85px;
        }
      }
    }
    &__recipient {
      border: solid 2px #f2f5f7;
      margin-bottom: 10px;
      margin-right: 20px;
      border-radius: 10px;
      &--active {
        background: #e9ecef;
      }
    }
    &__messages {
      flex: 62% 0 0;
      &-border {
        height: calc(100% - 120px);
        margin-bottom: 20px;
        background-color: white;
        border-radius: 27px;
        padding: 28px;
        &-list {
          height: 100%;
          background-color: white;
          overflow: auto;
          border-radius: 10px;
          border: solid 3px #f2f5f7;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        &-content {
          padding: 20px 0;
          min-height: 700px;
        }
      }
      &-date {
        text-align: center;
        font-family: var(--font-extrabold, var(--font-bold));
        font-size: toRem(10);
        position: sticky;
        width: 100%;
        background-color: white;
        padding: 10px 0;
        top: 0;
        @include for-lg {
          font-size: toRem(16);
        }
      }
    }
    &__message {
      margin: 10px 0;
    }
    &__send {
      float: right;
      width: 100%;
      height: 100px;
      position: relative;
      background: #fff;
      border-radius: 20px;
      &-input {
        width: 100%;
        margin: 0px 10px;
        border-radius: 5px;
        padding: 10px 80px 20px 10px;
        height: 100%;
        line-height: 1.6;
        font-family: var(--font);
        border: none;
        outline: none;
        resize: none;
        overflow: hidden;
        font-size: toRem(11);
        @include for-lg {
          font-size: toRem(17);
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &-btn {
        position: absolute;
        right: 5px;
        cursor: pointer;
        top: 30px;
        border: none;
        background: none;
        flex: 40px 0 0;
      }
    }
    &.chat-list-ce {
      .chat-list-content {
        .chat-list__recipients {
          .chat-list__search-bar {
            .chat-list__search-icon {
              g {
                stroke: var(--bg-alternate);
              }
            }
          }
          .chat-list__recipients-list {
            .recipient__circle {
              background-color: var(--primary) !important;
            }
          }
        }
      }
    }
    &.chat-list-jepa {
      .chat-list__recipients {
        .chat-list__search-bar {
          .chat-list__search-icon {
            path {
              fill: var(--bg-primary);
            }
            g {
              stroke: var(--bg-alternate);
            }
          }
        }
        .chat-list__recipients-list {
          .recipient__icon {
            path {
              fill: var(--bg-primary);
            }
          }
        }
      }
      .chat-list__messages {
        .chat-list__messages-list {
          padding: 1rem;
          .chat-message__content {
            .chat-message__bubble {
              background-color: var(--text-alternate);
              color: #fff;
            }
          }
          .chat-message__last-seen {
            padding-right: 10px;
            margin-bottom: 2px;
          }
        }
      }
    }
    &.chat-list-lcm {
      .chat-list__recipients {
        .chat-list__search-bar {
          .chat-list__search-icon {
            g {
              stroke: var(--bg-primary);
            }
          }
        }
      }
    }
    &.chat-list-gc {
      .chat-list__recipients {
        .chat-list__search-bar {
          .chat-list__search-icon {
            g {
              stroke: var(--color-icon-6);
            }
          }
        }
        &-list {
          .chat-list__recipient {
            .recipient {
              &__name {
                font-family: var(--font-bold) !important;
                font-size: 18px !important;
              }
              &__icon {
                path {
                  fill: var(--bg-primary);
                }
              }
              &__last-message {
                font-family: var(--font-x) !important;
                font-size: 16px !important;
                font-weight: 400;
              }
            }
            &:nth-child(6n + 1) {
              .recipient {
                &__circle {
                  background-color: var(--color-icon-1) !important;
                }
              }
              .md-avatar-icon {
                background-color: var(--color-icon-1) !important;
              }
            }
            &:nth-child(6n + 2) {
              .recipient {
                &__circle {
                  background-color: var(--color-icon-2) !important;
                }
              }
              .md-avatar-icon {
                background-color: var(--color-icon-2) !important;
              }
            }
            &:nth-child(6n + 3) {
              .recipient {
                &__circle {
                  background-color: var(--color-icon-3) !important;
                }
              }
              .md-avatar-icon {
                background-color: var(--color-icon-3) !important;
              }
            }
            &:nth-child(6n + 4) {
              .recipient {
                &__circle {
                  background-color: var(--color-icon-4) !important;
                }
              }
              .md-avatar-icon {
                background-color: var(--color-icon-4) !important;
              }
            }
            &:nth-child(6n + 5) {
              .recipient {
                &__circle {
                  background-color: var(--color-icon-5) !important;
                }
              }
              .md-avatar-icon {
                background-color: var(--color-icon-5) !important;
              }
            }
            &:nth-child(6n) {
              .recipient {
                &__circle {
                  background-color: var(--color-icon-6) !important;
                }
              }
            }
            .md-avatar-icon {
              background-color: var(--color-icon-6) !important;
            }
          }
        }
      }
      .chat-list__messages {
        &-list {
          .chat-message__bubble {
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      .md-button {
        font-size: 13px;
        font-family: var(--font-bold);
      }
    }
  }
}
</style>
