var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-list",class:_vm.chatListClass},[_c('Banner',[_c('div',{staticClass:"banner-content"},[_c('MeepIconChat',{staticClass:"banner-content__icon"}),_c('span',{staticClass:"banner-content__text"},[_vm._v(_vm._s(_vm.$t("menu.chat")))])],1)]),_c('div',{staticClass:"chat-list-content page-layout"},[_c('div',{staticClass:"chat-list__content",class:{
        'chat-list__content--mobile': _vm.isMobile,
        'chat-list__content--jep': _vm.isJEP,
      }},[((!_vm.showChat && _vm.isMobile) || !_vm.isMobile)?_c('div',{staticClass:"chat-list__recipients"},[_c('div',{staticClass:"chat-list__search-bar"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.recipientQuery),expression:"recipientQuery"}],staticClass:"chat-list__search-input",attrs:{"name":"recipientQuery","placeholder":"","type":"text"},domProps:{"value":(_vm.recipientQuery)},on:{"input":function($event){if($event.target.composing)return;_vm.recipientQuery=$event.target.value}}}),_c('MeepIconSearchi',{staticClass:"chat-list__search-icon"})],1),(_vm.filter.length)?_c('md-list',{staticClass:"chat-list__recipients-list md-double-line"},_vm._l((_vm.filter),function(chat,index){return _c('Recipient',{key:index,staticClass:"chat-list__recipient",class:{
              'chat-list__recipient--active': chat.id === _vm.currentThreadId,
            },attrs:{"chat-id":chat.id,"last-message":chat.last_message,"name":chat.name,"unread-count":chat.unread_count},on:{"showChatDetail":_vm.onShowChatDetail}})}),1):_c('md-empty-state',{attrs:{"md-description":"Aucune conversations trouvées"}})],1):_vm._e(),(((_vm.showChat && _vm.isMobile) || !_vm.isMobile) && _vm.initDone)?_c('div',{staticClass:"chat-list__messages"},[_c('div',{staticClass:"chat-list__messages-border"},[_c('div',{staticClass:"chat-list__messages-border-list"},[_c('div',{staticClass:"chat-list__messages-border-content"},[(_vm.isLoadMessageMore)?_c('div',{staticClass:"chat-list__load-more"},[_c('md-button',{staticClass:"md-primary load-more",nativeOn:{"click":function($event){return _vm.loadMoreMessage.apply(null, arguments)}}},[_vm._v(" Charger plus... ")])],1):_vm._e(),_vm._l((_vm.messages.key),function(messageDate,index){return _c('div',{key:index},[_c('p',{staticClass:"chat-list__messages-date"},[_vm._v(_vm._s(messageDate))]),_vm._l((_vm.messages.data[messageDate]),function(message,index){return _c('ChatMessage',{key:index,staticClass:"chat-list__message",attrs:{"id":`chat-message-${message.id}`,"is-last-message":index === _vm.messages.data[messageDate].length - 1 &&
                      messageDate === _vm.messages.key[_vm.messages.key.length - 1],"message":message,"users":_vm.users}})})],2)})],2)])]),_c('form',{staticClass:"chat-list__send",on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],staticClass:"chat-list__send-input",attrs:{"readonly":_vm.isSendingMessage,"placeholder":"Message...","type":"text"},domProps:{"value":(_vm.text)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if(!$event.shiftKey)return null;if($event.ctrlKey||$event.altKey||$event.metaKey)return null;return _vm.newline.apply(null, arguments)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.sendMessage.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.text=$event.target.value}}}),_vm._v(" "),_c('button',{staticClass:"chat-list__send-btn",attrs:{"disabled":_vm.isSendingMessage,"type":"submit"}},[(_vm.isSendingMessage)?_c('md-progress-spinner',{attrs:{"md-diameter":20,"md-stroke":3,"md-mode":"indeterminate"}}):_vm._e(),_c('MeepIconSend',{staticClass:"chat-list__send-icon"})],1)])]):_vm._e(),_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
          'md-primary': !_vm.isJEP,
          'md-alternate': _vm.isJEP,
        },on:{"click":_vm.downloadMessages}},[_c('MeepIconDownload',{staticClass:"app-icon"})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }