<template>
  <md-list-item class="recipient">
    <button
      type="button"
      class="recipient__btn"
      @click="$emit('showChatDetail', chatId)"
    >
      <div class="recipient__circle">
        <MeepIconHomepageChat class="app-icon" />
      </div>

      <div class="recipient__information">
        <div class="recipient__text">
          <p class="recipient__name">
            {{ name }}
          </p>

          <p
            class="recipient__last-message-at"
            v-if="lastMessage"
            v-html="$$filters.formatTimestampInChat(lastMessage.created_at)"
          />
        </div>

        <p
          v-if="lastMessageContent"
          class="recipient__last-message"
          :class="{ unread: unreadCountNumber > 0 }"
        >
          {{ lastSender }}: {{ lastMessageContent }}
        </p>
      </div>

      <md-avatar
        v-if="unreadCountNumber > 0"
        class="md-avatar-icon md-small"
        :class="{ 'md-accent': unreadCountNumber > 0 }"
      >
        {{ unreadCountNumber }}
      </md-avatar>
    </button>
  </md-list-item>
</template>

<script>
import MeepIconHomepageChat from "../../components/icons/MeepIconHomepageChat";
import { mapGetters } from "vuex";

export default {
  name: "Recipient",

  components: {
    MeepIconHomepageChat,
  },

  props: {
    chatId: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    lastMessage: {
      type: Object,
      default: () => null,
    },

    unreadCount: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters(["self"]),

    lastSender() {
      if (!this.lastMessage) {
        return "";
      }
      return this.lastMessage.id === this.self.id
        ? "Vous"
        : `${this.lastMessage.firstname} ${this.lastMessage.lastname}`;
    },

    lastMessageContent() {
      if (!this.lastMessage || !this.lastMessage.content) {
        return "";
      }
      return this.lastMessage.content.length > 64
        ? `${this.lastMessage.content.slice(0, 60)}...`
        : this.lastMessage.content;
    },

    unreadCountNumber() {
      return Number.parseInt(this.unreadCount);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variable.scss";
.md-theme-default .recipient {
  &__btn {
    cursor: pointer;
    width: 100%;
    border: none;
    background: none;
    display: flex;
    align-items: center;
  }

  &__icon {
    flex: 24px 0 0;
  }

  &__information {
    flex: 1;
    overflow: hidden;
  }

  &__text {
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__last-message {
    font-family: var(--font-x);
    margin: 0;
    padding: 0 10px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }

  &__name {
    display: inline-block;
    font-family: var(--font-extrabold, var(--font-bold));
    font-size: 1rem;
    margin: 0;
  }

  &__last-message-at {
    display: inline-block;
    margin: 0;
    text-align: right;
    font-size: toRem(6);
    @include for-lg {
      font-size: toRem(10);
    }
  }

  &__circle {
    width: 25px;
    height: 25px;
    background-color: var(--bg-primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include for-lg {
      width: 37px;
      height: 37px;
      .app-icon {
        width: 18px;
        height: 18px;
      }
    }
    .app-icon {
      height: 15px;
      width: 15px;
      path {
        fill: var(--text-primary);
      }
    }
  }
}
</style>
