<template>
  <div class="chat-message" :class="{ 'chat-message--owner': isOwner }">
    <div class="chat-message__content">
      <div
        v-if="sender"
        class="chat-message__avatar"
        :style="{ 'background-color': `${avatarColor}` }"
      >
        {{ sender.initials }}
      </div>

      <div class="chat-message__bubble">
        {{ message.content }}
        <md-tooltip
          v-if="message.created_at"
          :md-direction="isOwner ? 'left' : 'right'"
        >
          {{ $$filters.formatTimestamp(message.created_at) }}
        </md-tooltip>
      </div>
    </div>

    <div v-if="message.isFailed" class="failed">
      <md-avatar class="md-avatar-icon md-small md-accent">
        <md-icon>clear</md-icon>

        <md-tooltip>Le message n'a pas pu être envoyé</md-tooltip>
      </md-avatar>
    </div>
    <div v-if="isLastMessage" class="chat-message__seen-by">
      <div
        v-for="(id, i) in message.seen_by"
        :key="i"
        class="chat-message__avatar chat-message__avatar--small"
        :style="{
          'background-color': `${userAvatarColor(id)}`,
        }"
      >
        <span v-if="users[id]"> </span>

        <md-tooltip v-if="users[id]">
          {{ `${users[id].firstname} ${users[id].lastname}` }}
        </md-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { stringToHslColor } from "@/services/util";

export default {
  name: "ChatMessage",

  props: {
    users: {
      type: Object,
      default: () => ({}),
    },

    message: {
      type: Object,
      required: true,
    },

    isLastMessage: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters(["self"]),

    isOwner() {
      return this.message.user_id === this.self.id;
    },

    sender() {
      return this.users[this.message.user_id];
    },

    avatarColor() {
      // No avatar when message has no sender
      if (!this.sender) {
        return "";
      }

      return stringToHslColor(this.message.user_id + this.sender.lastname);
    },

    displaySeenAt() {
      return this.isLastMessage && this.message.created_at;
    },
  },

  methods: {
    userAvatarColor(id) {
      if (this.users && this.users[id]) {
        return stringToHslColor(id + this.users[id].lastname);
      }

      return "none";
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/_variable.scss";
.chat-message {
  width: 100%;

  &__content {
    display: flex;
    flex-direction: row;
  }

  &__avatar {
    margin: 0 20px;
    flex: 40px 0 0;
    border-radius: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-primary);
    font-family: var(--font-extrabold);
    &--small {
      flex: 18px 0 0;
      border-radius: 50%;
      height: 18px;
      font-size: 0.75rem;
      margin: 0px;
      margin-right: 7px;
      &:first-child {
        margin-left: 27px;
      }
    }
  }

  &__header {
    margin-left: 60px;
    margin-right: 0;
    margin-bottom: 6px;
  }

  &__bubble {
    background-color: #f2f5f7;
    padding: 10px;
    border-radius: 21px;
    max-width: 500px;
    font-family: var(--font-x);
    line-height: 1.35;
    font-size: toRem(11);
    @include for-lg{
      font-size: toRem(17);
    }
    &--push {
      margin-left: 80px;
      margin-right: 0;
    }
  }

  &__seen-by {
    margin-top: 5px;
    display: flex;
  }

  &__last-seen {
    width: 100%;
    font-style: italic;
    font-size: 0.75rem;
    color: var(--text-secondary);
    text-align: left;
    margin-bottom: 0;
  }

  &--owner {
    .chat-message__header {
      text-align: right;
      margin-right: 60px;
      margin-left: 0;
    }

    .chat-message__last-seen {
      text-align: right;
    }

    .chat-message__content {
      flex-direction: row-reverse;
    }

    .chat-message__bubble {
      background-color: var(--bg-third, #000);
      color: var(--text-primary, #fff);
      border-radius: 21px;
      max-width: 500px;
      padding: 12px 24px;
      font-size: toRem(11);
      @include for-lg{
        font-size: toRem(17);
      }
      &--push {
        margin-right: 80px;
        margin-left: 0;
      }
    }

    .chat-message__seen-by {
      flex-direction: row-reverse;
      .chat-message__avatar--small {
        margin: 0px;
        margin-right: 7px;
        &:first-child {
          margin-right: 27px;
        }
      }
    }
  }
}
</style>
